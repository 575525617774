<template>
  <div class="" style="width: 100%;height: 100%;">
    <el-dialog title="" :visible.sync="dialogVisible_verify" custom-class="cus_dialog cus_dialogFullHeight" width="90%"
      :close-on-click-modal="false" @closed="onClose">
      <div class="myDialogBox flexBetween">
        <div class="flexCloumnSingleCenter" style="height:100%;width: 40%;">
          <div class="boxShadow flexCloumnSingleCenter" style="height: 60%;width: calc( 100% - 0px );">
            <div class="titleBar flexStart">
              <div class="div flexStart">{{ dialogTitle }}</div>
            </div>
            <div class="flex_1" style="width:100%;overflow-y: auto;margin-bottom: 10px;">
              <el-form class="" :model="EditItem" ref="addzc" label-position="left" label-width="90px"
                style="width:calc( 100% - 10px );">

                <el-form-item label="申请人:">{{ EditItem.username }}
                </el-form-item>
                <el-form-item label="所在部门:">{{ EditItem.dept_name }}</el-form-item>
                <el-form-item label="申请时间:">{{ EditItem.cdate }}</el-form-item>
                <el-form-item label="领用日期:">{{ EditItem.ly_date ? EditItem.ly_date.substr(0, 10) : '-' }}
                </el-form-item>
                <el-form-item label="备注:" prop="sl_remark">
                  <el-input type="textarea" :rows="5" :value="EditItem.sl_remark||'-'" size="mini" disabled></el-input>
                </el-form-item>
                <el-form-item label="相关图片:">
                  <div class="flexStart">
                    <div v-if="EditItem.files&&EditItem.files.length>0" class="updimage"
                      v-for="(f, idx) in EditItem.files" :style="{ backgroundImage: 'url(' + f.src + ')' }"
                      style="position: relative">
                      <img :src="f.src" @click="handlePictureCardPreview(f.src)"
                        style="opacity: 0;width: 100%;height: 100%;cursor:pointer;" />
                    </div>

                    <div v-if="!EditItem.files||EditItem.files.length==0" class="">-</div>
                  </div>
                </el-form-item>
              </el-form>

            </div>
          </div>


          <div class="shadow flexCloumn mt10" style="height:  calc( 40% - 10px);width: 100%;">
            <div class="titleBar flexStart">
              <div class="div flexStart">流程状态</div>
            </div>
            <processviewmini ref="processviewmini" :process_code="process_code" table_name="zichan_sl"
              :form_id="EditItem.id" class="flex_1" style="overflow-y: auto;margin: 10px 0;">
            </processviewmini>
          </div>
        </div>
        <div class="shadow flexCloumnSingleCenter" style="height: 100%;width: calc( 60% - 10px);">
          <el-table ref="mySGtable" :data="EditItem.hc_list" height="100%" stripe border
            style="width:fit-content;width:100%;">

            <!--              <el-table-column type="selection" width="55" />-->
            <el-table-column type="index" label="序号" width="55" />
            <el-table-column prop="title" label="耗材名称" width="200" :show-overflow-tooltip="true">
              <!--                  <template slot-scope="scope">-->
              <!--                    <div @click="showDetails(scope.row)" style="color: #409EFF;cursor: pointer;">{{ scope.row.title }}-->
              <!--                    </div>-->
              <!--                  </template>-->
            </el-table-column>

            <!-- <el-table-column prop="typename1" label="耗材分类" width="120" :show-overflow-tooltip="true"/> -->
            <el-table-column width="100" prop="pinpai" label="耗材品牌" />
            <el-table-column width="100" prop="xinghao" label="耗材型号" />
            <el-table-column prop="storename" label="所在仓库" width="200" />
            <!-- <el-table-column prop="jldw" label="计量单位" width="100" align="center"/> -->
            <el-table-column prop="store_num" label="剩余库存" width="100" align="center" />

            <el-table-column prop="slNum" width="150" align="center">
              <template slot="header">
                <span style="color: #F56C6C">申领数量</span>
              </template>
              <template slot-scope="scope">
                <span style="color: #F56C6C">{{ scope.row.slNum }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="user_remark" label="备注" :show-overflow-tooltip="true" width="200">
              <template slot-scope="scope">{{ scope.row.user_remark || '-' }}</template>
            </el-table-column>

          </el-table>
        </div>
      </div>
      <div slot="footer" class="flexEnd">
        <span v-if="icansp" style="white-space: nowrap;">备注：</span>
        <el-input v-if="icansp" v-model="EditItem.checkReason" size="small" placeholder="驳回时请填写驳回原因"
          style="margin-right: 20px;"></el-input>

        <el-button v-if="icansp" type="danger" @click="onVerify(2)" size="small">审核驳回</el-button>
        <el-button v-if="icansp" type="success" @click="onVerify(1)" size="small">审核通过</el-button>

        <el-button type="primary" @click="onClose" plain size="small">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import processviewmini from "../../../com/processviewmini.vue"

export default {
  components: {
    processviewmini,
  },
  data() {
    return {
      //--审批流程用----
      process_code: "hcsl",
      process_id: 0,
      needChoose: false,//是否需要选择下级
      choosedUser: [],//已选择的用户
      //--审批流程用----
      disabled: false,
      files: [],
      arr_json: [],
      icansp: false,
    }
  },
  props: ['dialogVisible_verify', 'dialogTitle', 'EditItem'],
  mounted() {
    console.log(174, this.EditItem)
  },
  methods: {
    //--审批流程用----
    isChooseuser(e) {//设置是否需要选人
      this.needChoose = e
    },
    chooseUser(e) {//设置选择的人
      this.choosedUser = e
    },
    getProcessID() {
      this.$http.post("/api/form_process", {
        process_code: this.process_code
      }).then(res => {
        this.process_id = res.data.id

      })
    },
    //--审批流程用----
    handlePictureCardPreview(src) {
      console.log(this.$parent)
      this.$$parent(this, 'handlePictureCardPreview', src)
    },
    onClose() {
      this.$$parent(this, 'dialogVisible_verify', false)
    },
    onVerify(status) {
      let _this = this
      if (status == 2 && !this.EditItem.checkReason) {
        _this.$message.error(`请填写驳回原因~`)
        return
      }
      this.$confirm(`是否确认${status == 1 ? '通过' : '驳回'}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs["processviewmini"].startSp(status, this.EditItem.checkReason)
      }).catch(() => {

      });
    },
    afterCallBack(){
      this.onClose()
      this.$parent.$refs.SXShenLTable.getList()
    }

  }
}
</script>


